import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { SecondSightContent } from "../../content/blog/second-sight"
import { SettingsContext } from "../../contexts/settings"

const SecondSightPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = SecondSightContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#second-sight" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    <b>
                        <b>{content.firstFirst}</b>
                        <a className="link-bold" href="https://lucyduggan.com/" target="_blank" rel="noopener noreferrer">{content.firstLinkFirst}</a>
                        <b>{content.firstSecond}</b>
                        <a className="link-bold" href="https://hu-berlin.academia.edu/EvavonRedecker" target="_blank" rel="noopener noreferrer">{content.firstLinkSecond}</a>
                        <b>{content.firstThird}</b>
                        <Link className="link-bold" to="/blog/lesbian-seeing" >{content.firstLinkThird}</Link>
                    </b>
                </p>
                <p class="text-middle text-indented">
                    {content.secondFirst}
                    <Link className="link-middle" to="/blog/lesbian-seeing" >{content.secondLink}</Link>
                    {content.secondSecond}
                </p>
                <p class="text-middle text-indented">
                    {content.third}
                    <a className="link-middle" href="https://www.facebook.com/events/892243821305440/" target="_blank" rel="noopener noreferrer">{content.thirdLink}</a>
                </p>
                <p>
                    {content.fourth}
                </p>
                <p>
                    {content.fifth}
                </p>
                <p>
                    {content.sixth}
                </p>
                <p>
                    {content.seventh}
                </p>
                <p>
                    {content.eighth}
                </p>
                <p>
                    {content.ninth}
                </p>
                <p>
                    {content.tenth}
                </p>
                <p>
                    {content.eleventh}
                </p>
                <p>
                    {content.twelfth}
                </p>
                <p>
                    {content.thirteenth}
                </p>
                <p>
                    {content.fourteenth}
                </p>
                <p>
                    {content.fifteenth}
                </p>
                <p class="small end-of-site">
                    {content.sixteenth}
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default SecondSightPage