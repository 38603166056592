const SecondSightContent = {
    german: {
        date: "2. November 2020",
        arrow: "BLOG << ",
        headline: "Second Sight / das zweite Gesicht. Translator’s Note on ‚Lesbian Seeing‘",
        firstFirst: "A guest contribution by ",
        firstLinkFirst: "Lucy Duggan",
        firstSecond: ", replying to ",
        firstLinkSecond: "Eva von Redecker’s",
        firstThird: " text‚ ",
        firstLinkThird: "‚Lesbian Seeing‘",
        secondFirst: "nOu’s remark: Lucy Duggan and Eva von Redecker planned to make Lucy’s following reply to ",
        secondLink: "Eva’s text, which you can read by clicking here",
        secondSecond: ", the entry points for a lecture performance during Nocturnal Unrest. However, due to the latter’s postponement, Eva and Lucy won’t be able to perform which we deeply regret. We’re grateful that, instead, they provided us with the original texts, granting us invaluable insight into their philosophical exchange and their perspectives on what we perceive depending on who we are – and what might remain in the dark.",
        third: "Starting in November, nOu is offering a digital seminar series (in German language) based on their exchange including a joint discussion with Eva and Lucy. ",
        thirdLink: "Click here for more information.",
        fourth: "Walking in the fields, where half the sky is bright pink, and the other half is already dark and still, I turn around and feel that I am two selves drifting away from each other. The self which reflects has no access to the one which acts. The self which lives is floating away from me. She is a comet with a long tail filled with debris that obscures my vision.",
        fifth: "A few weeks ago, I finally got glasses. I have not become accustomed to the constant presence of a frame (two frames) around my field of vision. I resent the fact that they get earth on them when I weed the herb bed, and the fact that rain-drops gather on the lenses.",
        sixth: "I look different. Someone asks me: “Are those yours?” As if they were part of a costume. With people I know well, I find myself removing them to talk, as if they made me hard of hearing, or hindered me from seeing faces clearly. With strangers, I feel protected, as if the glasses shielded me, hiding my eyes.",
        seventh: "I have gained details: foxes creeping in far fields, high leaves, crumbling mortar between the bricks on the wall of the barn. I can read the clock face in the kitchen. I look forward to going to the theatre and seeing twitches in mouths and eyebrows.",
        eighth: "I have also gained a second face. Second face, second sight. In German, the phrase “second sight” makes use of an old word, Gesicht, which once meant sight, now means face. The queasy connection between seeing and being seen. As if a woman who ‘saw’ beyond the ordinary also reared her head in another realm, made herself seen and known there, let her second face be traced by other powers.",
        ninth: "It is something like that when I see my second self streaming away from me like a long-tailed comet. It reminds me of what I read about orbits and escape velocity: how sometimes, two bodies pass each other, feel the heat, and then separate, never to meet again. I am afraid that my second self is swirling into the stratosphere before I’ve had a chance even to write about her.",
        tenth: "When Eva wrote her text on Lesbisches Sehen, we talked about whether it excluded bisexual women. I loved the text and felt part of it, with its wealth of literary allusions which I understood. I was full of pride inside that text: I existed within it, and I had to believe I could move freely there, leave it and return. (It seemed I would only lose by claiming that it excluded me.) Now, I am translating it and enjoying the coincidence of finding words for Eva’s ideas about sight and seeing, while wearing these new glasses for short-sightedness.",
        eleventh: "And yet, I feel less sure these days about whether the lesbian gaze is a freedom, a power I am allowed to have. My second self used to be docile, because she remained within. Now she wriggles away. I could tattoo her image on my body, it wouldn’t stop her leaving me if she wanted to. She is my second face which shows itself in a parallel realm, and I see her stepping through a wall in the air, so far away that it is difficult for me to see her clearly.",
        twelfth: "At the main station in Berlin, I watch a woman abseiling down from the roof. Reaching the ground, she begins to untangle the ropes. She yells up to her colleagues: “Don’t come down yet. The ropes are knotted.” She takes off her helmet; it has flattened the strip of hair which divides her shaved head into two beautiful hemispheres. I want to ask for her autograph; I want to join her army; I want to find a way to make her look at me. I write a message to her on a page from a notebook, but I’m too slow: by the time I’ve perfected it, she’s helped each of the men lower themselves down the final concave rock-face above the station pub, and she’s already disappeared indoors. I follow, walking beside the men. There’s no sign of her, but the men are heading for a service entrance on the ground floor; I consider giving the note to one of them, telling him to pass it on, but I don’t. I would be trying to pass a message via another universe. But more importantly: she never saw me, why would she ever call?",
        thirteenth: "I have been thinking that writing about my second self is like lassoing the comet, drawing her close to me again. (Then, at the station, I think that she is abseiling down to meet me, floating on her rope.) Or it’s like widening the frame, zooming out until my field of vision captures her again — like a scene in a film where the camera moves back and back, and what you thought was a dark blue sky is actually the iris of an eye. When she seems to leave me, I am thrown into shadow. But when she reappears, it begins to feel like a game. We are the moon of Jupiter which came to be known as a strange, unpredictable body: named Janus, because of its “two-faced” nature. Only later did astronomers spot that these are two moons following almost the same orbit — probably two fragments of what was once a whole.",
        fourteenth: "But the game is serious. It has rules, draws lines, compelling me to follow. There is a binary, uncomfortable. Who, now, would agree to be circumscribed like that? To desire both of two genders, to be seen either as gay or as straight, to possess, or not, the gift of the lesbian gaze. The answers to this are well known. Instead of “bisexual”, there are other words: “pansexual” and “queer”. So why am I straining my eyes to catch sight of my “second self”? Am I subscribing, inscribing myself with false dichotomies? (Too easy, unmasking a dichotomy like that, like unmasking a bisexual woman who claims to be a lesbian.)",
        fifteenth: "For now, at least, this binary is what I see. “Being bisexual” does not feel like anything, it does not look like anything or see like anything: it is an empty space, vacant, and my vagrant selves fly far beyond it, outside their paths of migration. There is no bisexual body. There is a body which desires its own gender, and a body which desires other genders. What would it mean to turn “my” lesbian gaze on the face of the man I am in love with? I am tempted to answer flippantly: he would turn from a prince into a frog. He would crumble, along with the patriarchy. He would timidly cook dinner for me and my female lovers. He would become invisible and irrelevant, like the father of Carla’s daughter in the lesbian universe of We Go Around In The Night And Are Consumed By Fire. But before it starts to sound as if I’m pleading for a place for him in that other realm: I’m not afraid for him. It is my own doubled path which seems to be unwritable, incalculable.",
        sixteenth: "– written in 2018"
    },
    english: {
        date: "November 2, 2020",
        arrow: "BLOG << ",
        headline: "Second Sight / das zweite Gesicht. Translator’s Note on ‚Lesbian Seeing‘",
        firstFirst: "A guest contribution by ",
        firstLinkFirst: "Lucy Duggan",
        firstSecond: ", replying to ",
        firstLinkSecond: "Eva von Redecker’s",
        firstThird: " text ‚",
        firstLinkThird: "‚Lesbian Seeing‘",
        secondHeadlineFirst: "nOu’s remark: Lucy Duggan and Eva von Redecker planned to make Lucy’s following reply to ",
        secondLink: "Eva’s text, which you can read by clicking here",
        secondSecond: ", the entry points for a lecture performance during Nocturnal Unrest. However, due to the latter’s postponement, Eva and Lucy won’t be able to perform which we deeply regret. We’re grateful that, instead, they provided us with the original texts, granting us invaluable insight into their philosophical exchange and their perspectives on what we perceive depending on who we are – and what might remain in the dark.",
        third: "Starting in November, nOu is offering a digital seminar series (in German language) based on their exchange including a joint discussion with Eva and Lucy. ",
        thirdLink: "Click here for more information.",
        fourth: "Walking in the fields, where half the sky is bright pink, and the other half is already dark and still, I turn around and feel that I am two selves drifting away from each other. The self which reflects has no access to the one which acts. The self which lives is floating away from me. She is a comet with a long tail filled with debris that obscures my vision.",
        fifth: "A few weeks ago, I finally got glasses. I have not become accustomed to the constant presence of a frame (two frames) around my field of vision. I resent the fact that they get earth on them when I weed the herb bed, and the fact that rain-drops gather on the lenses.",
        sixth: "I look different. Someone asks me: “Are those yours?” As if they were part of a costume. With people I know well, I find myself removing them to talk, as if they made me hard of hearing, or hindered me from seeing faces clearly. With strangers, I feel protected, as if the glasses shielded me, hiding my eyes.",
        seventh: "I have gained details: foxes creeping in far fields, high leaves, crumbling mortar between the bricks on the wall of the barn. I can read the clock face in the kitchen. I look forward to going to the theatre and seeing twitches in mouths and eyebrows.",
        eighth: "I have also gained a second face. Second face, second sight. In German, the phrase “second sight” makes use of an old word, Gesicht, which once meant sight, now means face. The queasy connection between seeing and being seen. As if a woman who ‘saw’ beyond the ordinary also reared her head in another realm, made herself seen and known there, let her second face be traced by other powers.",
        ninth: "It is something like that when I see my second self streaming away from me like a long-tailed comet. It reminds me of what I read about orbits and escape velocity: how sometimes, two bodies pass each other, feel the heat, and then separate, never to meet again. I am afraid that my second self is swirling into the stratosphere before I’ve had a chance even to write about her.",
        tenth: "When Eva wrote her text on Lesbisches Sehen, we talked about whether it excluded bisexual women. I loved the text and felt part of it, with its wealth of literary allusions which I understood. I was full of pride inside that text: I existed within it, and I had to believe I could move freely there, leave it and return. (It seemed I would only lose by claiming that it excluded me.) Now, I am translating it and enjoying the coincidence of finding words for Eva’s ideas about sight and seeing, while wearing these new glasses for short-sightedness.",
        eleventh: "And yet, I feel less sure these days about whether the lesbian gaze is a freedom, a power I am allowed to have. My second self used to be docile, because she remained within. Now she wriggles away. I could tattoo her image on my body, it wouldn’t stop her leaving me if she wanted to. She is my second face which shows itself in a parallel realm, and I see her stepping through a wall in the air, so far away that it is difficult for me to see her clearly.",
        twelfth: "At the main station in Berlin, I watch a woman abseiling down from the roof. Reaching the ground, she begins to untangle the ropes. She yells up to her colleagues: “Don’t come down yet. The ropes are knotted.” She takes off her helmet; it has flattened the strip of hair which divides her shaved head into two beautiful hemispheres. I want to ask for her autograph; I want to join her army; I want to find a way to make her look at me. I write a message to her on a page from a notebook, but I’m too slow: by the time I’ve perfected it, she’s helped each of the men lower themselves down the final concave rock-face above the station pub, and she’s already disappeared indoors. I follow, walking beside the men. There’s no sign of her, but the men are heading for a service entrance on the ground floor; I consider giving the note to one of them, telling him to pass it on, but I don’t. I would be trying to pass a message via another universe. But more importantly: she never saw me, why would she ever call?",
        thirteenth: "I have been thinking that writing about my second self is like lassoing the comet, drawing her close to me again. (Then, at the station, I think that she is abseiling down to meet me, floating on her rope.) Or it’s like widening the frame, zooming out until my field of vision captures her again — like a scene in a film where the camera moves back and back, and what you thought was a dark blue sky is actually the iris of an eye. When she seems to leave me, I am thrown into shadow. But when she reappears, it begins to feel like a game. We are the moon of Jupiter which came to be known as a strange, unpredictable body: named Janus, because of its “two-faced” nature. Only later did astronomers spot that these are two moons following almost the same orbit — probably two fragments of what was once a whole.",
        fourteenth: "But the game is serious. It has rules, draws lines, compelling me to follow. There is a binary, uncomfortable. Who, now, would agree to be circumscribed like that? To desire both of two genders, to be seen either as gay or as straight, to possess, or not, the gift of the lesbian gaze. The answers to this are well known. Instead of “bisexual”, there are other words: “pansexual” and “queer”. So why am I straining my eyes to catch sight of my “second self”? Am I subscribing, inscribing myself with false dichotomies? (Too easy, unmasking a dichotomy like that, like unmasking a bisexual woman who claims to be a lesbian.)",
        fifteenth: "For now, at least, this binary is what I see. “Being bisexual” does not feel like anything, it does not look like anything or see like anything: it is an empty space, vacant, and my vagrant selves fly far beyond it, outside their paths of migration. There is no bisexual body. There is a body which desires its own gender, and a body which desires other genders. What would it mean to turn “my” lesbian gaze on the face of the man I am in love with? I am tempted to answer flippantly: he would turn from a prince into a frog. He would crumble, along with the patriarchy. He would timidly cook dinner for me and my female lovers. He would become invisible and irrelevant, like the father of Carla’s daughter in the lesbian universe of We Go Around In The Night And Are Consumed By Fire. But before it starts to sound as if I’m pleading for a place for him in that other realm: I’m not afraid for him. It is my own doubled path which seems to be unwritable, incalculable.",
        sixteenth: "– written in 2018"
    }

}
export {SecondSightContent}